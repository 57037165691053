.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background-color: transparent;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0 !important;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header.scrolled {
    background-color: #232942;
}

.MuiAppBar-root.appbar {
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.appbar.scrolled {
    background-color: #232942;
}

.nav {
    display: flex;
    align-items: center;
    margin-left: 5em;
    margin-right: auto; /* Adjust this value for the space between "Safety" and "Log In" */
    font-size: 20px;
}

.nav a {
    margin: 0 1em;
    text-decoration: none;
    color: white; /* Specify the color here */
}

.auth-buttons {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.login-btn {
    border: 1px solid transparent;
    text-decoration: none; /* Remove underline */
    color: white; /* Specify the color here */
    margin-right: 2em;
}

.signup-btn {
    border: 1px solid white;
    color: white;
    padding: 5px 10px;
    text-decoration: none; /* Remove underline */
}

/* Add this media query at the bottom of the Header.css file */
@media screen and (max-width: 768px) {
    .nav {
        display: none;
    }

    .auth-buttons {
        font-size: 16px;
    }

    .logo a {
        font-size: 24px;
    }

    .header-content {
        padding: 0 1em;
    }
}