.safety-section {
    background-color: #1d2336 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.safety-content {
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
}

.safety-image img {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.safety-text h2 {
    margin-bottom: 20px;
}

.safety-text h4 {
    font-weight: 700;
    margin-bottom: 20px;
    color: white;
}

.checkmark {
    color: #7696CE;
    font-size: 1.2em;
    margin-right: 8px;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
    .safety-content {
        flex-direction: column;
        align-items: center;
    }

    .safety-image {
        width: 200px;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .safety-text {
        margin-bottom: 20px;
    }

    .safety-text h4 {
        margin-bottom: 20px;
    }

    .transparency {
        display: none;
    }

    .outter {
        height: 200px !important;
    }
}