/* Make padding included in the height */
*, *:before, *:after {
    box-sizing: border-box;
}

.side-menu {
    background-color: #2C3E50; /* Dark blue */
    color: white;
    height: 100vh;
    padding: 20px;
    width: 250px;
    position: sticky;
    font-family: 'Arial', sans-serif;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
}

.desktop-side-menu {
    width: 250px;
    height: 100vh;
}

.menu-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 10px;
}

.title-separator {
    border: 1px solid #34495E;
    margin-bottom: 20px;
}

.menu-item {
    padding: 10px 10px 10px 50px; 
    margin: 10px 0;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
}

.menu-item:hover {
    background-color: #34495E; /* Slightly lighter blue */
    color: #BDC3C7; /* Light gray text color */
}

.menu-item.active {
    font-weight: bold;
    background-color: #1A2F40; /* Darker blue */
    color: #ECF0F1; /* Soft white */
}

.separator {
    position: absolute;
    bottom: 150px; /* Position just above the logout button */
    left: 20px; /* Center the line */
    right: 20px;
    border: 1px solid #34495E; /* Slightly lighter blue */
    opacity: 1;
}

.logout-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #B85147; /* Softer shade of red */
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease;
}

.feedback-button {
    position: absolute;
    bottom: 80px;
    left: 20px;
    right: 20px;
    background-color: transparent; 
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease;
}

.button-text {
    margin-left: 30px;
    text-align: left;
}

.logout-button:hover {
    background-color: #992B2B; /* Darker red on hover */
}


/* Include previous CSS here, and add the new media query */
@media screen and (max-width: 768px) {
    .side-menu {
        display: none;
        width: 0;
    }

    .menu-title, .menu-item, .separator, .logout-button {
        display: none;
    }
}