/* ResumeBuilder.css */
.resume-builder-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .enhanced-resume {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* This targets the scrollbar itself */
.main-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* This targets the track (background) of the scrollbar */
.main-container::-webkit-scrollbar-track {
  background-color: #F0F0F0; /* Color of the track */
  border-radius: 10px; /* Makes the corners rounded */
}

/* This targets the handle (the part you drag) of the scrollbar */
.main-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the handle */
  border-radius: 10px; /* Makes the corners rounded */
}

/* This changes the color of the handle when hovered */
.main-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.textarea-scrollbar::-webkit-scrollbar,
.textarea-scrollbar textarea::-webkit-scrollbar {
  width: 8px;
}

.textarea-scrollbar::-webkit-scrollbar-track,
.textarea-scrollbar textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.textarea-scrollbar::-webkit-scrollbar-thumb,
.textarea-scrollbar textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.textarea-scrollbar,
.textarea-scrollbar textarea {
  scrollbar-color: #ccc #f1f1f1; /* for Firefox */
}