*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6; /* Dark blue */
    color: #BDC3C7; /* Light gray text color */
    font-family: 'Arial', sans-serif;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.profile-card {
    display: flex;
    overflow-y: auto;
    background-color: #34495E; /* Slightly lighter blue */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%;
    max-width: 1000px;
    max-height: calc(100vh - 40px);
    margin: 30px;
    padding: 20px;
}

.profile-image-container {
    text-align: center;
    margin-right: auto;
    margin-bottom: 20px;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #ECF0F1; /* Soft white */
}

.profile-info-container {
    flex: 1;
}

.profile-header {
    font-size: 24px;
    font-weight: bold;
    color: #ECF0F1; /* Soft white */
    margin-bottom: 15px;
}

.profile-info {
    color: #BDC3C7; /* Light gray text color */
    padding: 10px;
    background-color: #1A2F40; /* Darker blue */
    border-radius: 4px;
}

.profile-info p {
    font-size: 15px;
    margin: 8px 0;
}

.profile-info strong {
    font-weight: bold;
    color: #ECF0F1; /* Soft white */
}

.profile-item {
    margin-bottom: 10px;
}

.section-title {
    font-size: 20px;
    margin: 20px 0;
    font-weight: bold;
    color: #ECF0F1; /* Soft white */
}

.summary, .experience {
    font-size: 16px;
    color: #BDC3C7; /* Light gray text color */
    background-color: #1A2F40; /* Darker blue */
    border-radius: 4px;
    padding: 10px;
}

.summary pre, .experience pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.loading-icon {
    height: 80px;
    text-align: center;
  }

.loading-section {
    font-size: 16px;
    color: #BDC3C7; /* Light gray text color */
    background-color: #1A2F40; /* Darker blue */
    border-radius: 4px;
    padding: 10px;
    font-style: italic;
}

.loading-section p {
    text-align: center;
}

.preserve-spaces {
    white-space: pre-wrap;
}

.preserve-spaces ul, 
.preserve-spaces ol {
    padding-left: 2em; /* You can adjust this value */
    margin: 0; /* Removes default margin */
}

/* This targets the scrollbar itself */
.profile-card::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  /* This targets the track (background) of the scrollbar */
  .profile-card::-webkit-scrollbar-track {
    background-color: #F0F0F0; /* Color of the track */
    border-radius: 10px; /* Makes the corners rounded */
  }
  
  /* This targets the handle (the part you drag) of the scrollbar */
  .profile-card::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Color of the handle */
    border-radius: 10px; /* Makes the corners rounded */
  }
  
  /* This changes the color of the handle when hovered */
  .profile-card::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }

  .profile-header-section {
    margin-right: 20px;
}

.cool-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.cool-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
}

.cool-scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #bbb 30%, #ccc 90%);
    border-radius: 10px;
}

.cool-scrollbar::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #ccc 30%, #bbb 90%);
}

/* Add media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
    .profile-container {
      height: 100%;
    }

    .profile-card {
        height: 100%;
        margin: 0px;
        padding: 20px;
        border-radius: 0px;
        flex-direction: column;
    }

    .profile-header-section {
        display: flex;
        flex-direction: row;
    }

    .profile-image-container {
        margin-right: 20px; /* Remove the margin to the right of the image container */
    }

    .profile-info-container {
        margin-left: 0; /* Remove the left margin to make content take full width */
    }
  }