.hero-section {
    background-image: url('images/background.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.hero-section h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    text-align: center;
}

.hero-section .subtitle {
    font-size: 1.5rem;
    margin-bottom: 40px;
    max-width: 600px;
    text-align: center;
}

.try-evolve-btn {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.space {
    display: none;
}

/* Add this media query at the bottom of the HeroSection.css file */
@media screen and (max-width: 768px) {
    .hero-section {
        height: 100vh;
    }

    .hero-section h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .hero-section .subtitle {
        font-size: 1.2rem;
        margin-bottom: 20px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .try-evolve-btn {
        padding: 10px 20px;
        font-size: 1rem;
    }

    .space {
        display: flex;
    }
}

@media screen and (max-width: 599px) {
    .hero-section {
        height: 100vh;
    }

    .hero-section h1 {
        display: none;
    }

    .hero-section .subtitle {
        font-size: 1.2rem;
        margin-bottom: 20px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .try-evolve-btn {
        padding: 10px 20px;
        font-size: 1rem;
    }

    .space {
        display: flex;
    }
}
