.chat-container {
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  padding: 0;
  width: 100%;
  margin: 0px auto;
  height: 100vh;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.chat-header {
  text-align: center;
  margin-bottom: 16px;
  padding-bottom: 70px;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1; /* Take up remaining space */
  overflow-y: hidden; /* No overflow on this element */
}

.message-container {
  overflow-y: auto;
  flex: 1; /* It will take up the remaining space */
}

/* This targets the scrollbar itself */
.message-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* This targets the track (background) of the scrollbar */
.message-container::-webkit-scrollbar-track {
  background-color: #F0F0F0; /* Color of the track */
  border-radius: 10px; /* Makes the corners rounded */
}

/* This targets the handle (the part you drag) of the scrollbar */
.message-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the handle */
  border-radius: 10px; /* Makes the corners rounded */
}

/* This changes the color of the handle when hovered */
.message-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.input-field {
  background-color: white;
  color: #000000;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 0px;
  margin-right: 10px;
  font-size: 15px;
  border: none;
}

.input-field:focus {
  outline: none;
}

.user {
  background-color: #fff;
  color: #333;
  align-self: flex-end;
  
}

.input-form {
  display: flex;
  align-items: center;
  background-color: white;
  border: solid;
  border-color: #e1e1e1;
  border-radius: 15px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  width: calc(100% - 20px); /* Make it take full width - margins */
  max-width: 780px;
  margin: 0px auto;
  padding: 0 10px;
}

.cool-scrollbar textarea::-webkit-scrollbar {
  width: 6px;
}

.cool-scrollbar textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.cool-scrollbar textarea::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #bbb 30%, #ccc 90%);
  border-radius: 10px;
}

.cool-scrollbar textarea::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #ccc 30%, #bbb 90%);
}

.scroll-to-bottom-arrow {
  position: fixed;
  bottom: 120px;
  right: 25px;
  width: 25px;
  height: 25px;
  font-size: 12px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(100, 100, 100);
  border-color: rgb(220, 220, 220);
  border-radius: 100%;
  border-style: solid;
  border-width: thin;
}

.scroll-to-bottom-arrow:hover {
  background-color: #e1e1e1;
}

.message {
  display: flex;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 0rem;
  border-bottom: solid;
  border-bottom-width: thin;
  border-bottom-color: rgb(220, 220, 220);
  padding: 20px;
}

.message-content {
  display: flex;
  align-items: flex-start;
  max-width: 750px;
  margin: 0px auto;
  width: 100%;
}

.icon {
  margin-right: 10px;
  font-size: 20px;
  align-self: flex-start;
}

.message-icon {
  margin-right: 30px; 
  margin-left: 0px;
  align-self: flex-start;
  color: #9966CC;
  text-align: center;
}

.user-icon {
  color: #F1C40F;
}

.message-user-image, .message-system-image {
  border-radius: 50%; /* Make the image circular */
  width: 40px; /* Set width */
  height: 40px; /* Set height */
}

/* Optionally set additional styles */
.message-user-image {
  border: 0px solid #FFDF00; /* Golden Yellow border */
}

.message-system-image {
  border: 0px solid #9966CC; /* Amethyst Purple border */
}

.message-text pre {
  white-space: pre-wrap;
  font-family: inherit; /* Optional: Change font to match other text if desired */
}

.message-text {
  line-height: 1.5; /* Adjust the line-height value as needed */
  margin-right: 40px;
}

.loading-icon {
  height: 60px;
  color: rgba(179, 179, 179, 0.1);
}

.disclaimer {
  font-size: 0.7em;
  color: #888;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

/* Add media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
  .input-form {
    border-radius: 10px;
    height: 60px;
  }

  .input-field {
    font-size: 16px;
  }

  .message-content {
    padding: 0 10px; /* Add padding for smaller screens */
  }

  .message-text {
    margin-right: 10px; /* Adjust margin for smaller screens */
  }
  
  .scroll-to-bottom-arrow {
    bottom: 120px; /* Adjust position for smaller screens */
    right: 15px;
  }

  .disclaimer {
    font-size: 0.6em;
  }

  .message {
    padding: 10px;
  }
  
  .message-icon {
    margin-right: 20px; 
  }
}