/* This targets the scrollbar itself */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  /* This targets the track (background) of the scrollbar */
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #F0F0F0; /* Color of the track */
    border-radius: 10px; /* Makes the corners rounded */
  }
  
  /* This targets the handle (the part you drag) of the scrollbar */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Color of the handle */
    border-radius: 10px; /* Makes the corners rounded */
  }
  
  /* This changes the color of the handle when hovered */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  