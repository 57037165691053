.mobile-app-bar {
    display: none !important;
    background-color: #2C3E50 !important;
    height: 50px;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.mobile-drawer-paper {
    width: 250px;
    background-color: #2C3E50 !important;
    color: white !important;
}

.menu-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 10px;
}

.title-separator {
    border: 1px solid #34495E;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .mobile-app-bar {
        display: flex !important;
    }
}