/* Product Section */
.product-section {
    background-color: #232942 !important;
}

/* Product Content */
/*.product-content {
    margin: 0 auto;
    padding: 0px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}*/

/* Product Image */
/*.product-image {
    text-align: center;
    flex: 1;
    min-width: 300px;
    max-width: 50%;
}*/

.product-image img {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/*.product-text {
    padding: 0px;
    flex: 2;
    min-width: 300px;
    max-width: 50%;
}*/

.item-content {
    width: 100%; 
    text-align: center;  
}

.product-text h5 {
    margin-bottom: 25px;
    margin-top: 15px;
    color: #7696CE;
}

.product-text h6 {
    font-weight: 600;
    margin: 10px 0;
    color: white;
    text-transform: uppercase;
}

.product-text p {
    line-height: 1.6;
    color: white;
}

/* Check Marks */
.product-text .checkmark {
    color: #7696CE !important;
    padding-right: 8px;
    display: inline-block;
}

/* Button */
button {
    background-color: white;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #2F4858;
}

.first {
    padding: 0 32px;
}

/* Responsive for small devices */
@media (max-width: 768px) {
    .product-content {
        flex-direction: column;
    }

    .product-image, .product-text {
        max-width: 100%;
        padding: 10px 0;
    }

    .transparency {
        display: none;
    }

    .first {
        padding: 0 0;
    }

    .outter {
        height: 200px !important;
    }
}


